import "../../sass/landing_pages/landing_pages.scss";

import Carousel from "bootstrap/js/dist/carousel"; // eslint-disable-line no-unused-vars
import Tab from "bootstrap/js/dist/tab"; // eslint-disable-line no-unused-vars

import initBusinessAutocomplete from "../components/BusinessAutocomplete";
import initCityAutocomplete from "../components/CityAutocomplete";
import { initClipboard, scrollToElement } from "../core/utils";

export function initIndex() {
  const planningResourcesSection = document.querySelector(
    ".sohp-planning-resources-container",
  );

  document.querySelector(".learn-more-cta").addEventListener("click", () => {
    scrollToElement(planningResourcesSection, -40);
  });
}

export function initMemorialServicesLandingPage() {
  const bodyContainer = document.querySelector(".product-lp-body-container");

  document.querySelector(".learn-more-cta").addEventListener("click", () => {
    scrollToElement(bodyContainer, -60);
  });
}

export function initEndOfLifeResourcesLandingPage() {
  initClipboard();
}

export function initBusinessLandingPage(props) {
  initCityAutocomplete(props.businessLocalitySearch);
  initBusinessAutocomplete(props.businessSearch);
}
